import axios from "axios";
import { config }from "../Helpers/Config"
import { getAuthorization, removeAuthFromStorage } from "./Storage";

const request = async (options: any, page = null) => {
    const token = getAuthorization();
    const client = axios.create({
        baseURL: options.endpoint_type ? `${config.api_protocol + options.endpoint_type + config.api_url_service}` : config.api_url_cf
    })

    options.headers = {
        "Accept": "application/json",
		"Access-Control-Allow-Origin": "*",
		"App-Locale": "id",
		...(token && { Authorization: `Bearer ${token}` })
    }

    const onSuccess = async (response: any) => {
		// console.log("onSuccess options", options);
		// console.log("onSuccess response", response);
		return await response.data;
	};

    const onError = async (error: any) => {
        try {

            if ((error.response.status === 401) && page != "login") {
                const cleanStorage =  removeAuthFromStorage();
                if (cleanStorage) {
                    return window.location.reload()   
                }
            }
            else if (error.response.status === 422 && page == "login"){
                return "inactive";
            }

            return error.response.data
        } catch (error) {
            return null
        }
    }


    return client(options).then(onSuccess).catch(onError);
}

export default request
