import React, { FC, useEffect, Fragment, useState, useMemo, useRef } from "react";
import { Link, NavLink, RouteComponentProps } from "react-router-dom";

import axios from "axios";
import WSHeader from "./WSHeader";
import "./../../Style/leaderboard.css"

import TabsComponent from "../../TabsComponent/tabs";
import HeaderSalesman from "../Header/HeaderSalesman";

type SomeComponentProps = RouteComponentProps;
const WholesellerPage: FC<SomeComponentProps> = ({}) => {
  
  // constantanta untuk dapetin wholeseller code dari local storage
  const roundIdString = localStorage.getItem("roundId") || "";
  const wholesellerName = localStorage.getItem("nameSalesman");
  const wholesellerCode = localStorage.getItem("wholesellerCode") || "";
  const roundId = parseInt(roundIdString, 10); // Parse to integer
  const [value, setValue] = useState("1");
  const [groupName, setGroupName] = useState("");
  const groupNameLeaderboard = localStorage.getItem('groupName') || "";
  const groupNameCricket = localStorage.getItem('groupNameTopCriket') || "";
  const groupNameDigital = localStorage.getItem('groupNameTopDigital') || "";
  
  useEffect(() => {
    getGroupName()
  },[value])

  const getGroupName = () => {
    if (value === "1" && groupNameLeaderboard) {
      setGroupName(groupNameLeaderboard);
    } else if (value === "2" && groupNameCricket) {
      setGroupName(groupNameCricket);
    } else if (value === "3" && groupNameDigital) {
      setGroupName(groupNameDigital);
    }
  };

  return (
    <Fragment>
      <WSHeader groupName={groupName} value={value} setValue={setValue} />
      <TabsComponent
        wholesellerCode={wholesellerCode}
        roundId={roundId}
        value={value}
        setValue={setValue}
        updateGroupName={setGroupName}
      />
    </Fragment>
  );
};

export default WholesellerPage;
