import React from "react";
import { Redirect, Route } from "react-router-dom";

const PrivateRoute = (props: any) => {
  // const isAuth  = false

  const token = localStorage.getItem("auth");
  const role = localStorage.getItem("employeeRole");
  const pageAdmin = [
    "/admin",
    "/round",
    "/maintainprofile",
    "/uploaddata",
    "/roundsetting",
    "/wholesalernational",
    "/wholesalernationalleaderboard",
    "/retailernationalleaderboard"
  ];
  const pageWholesaler = ["/wholeseller"];
  const pageRetailer = ["/retailer"];
  const pageSalesman = ["/leaderboards", "/salesman", "/salesmanretailerleaderboard"];
  const pageSupervisor = ["/supervisor", "/supervisorleaderboards","/supervisorretailerleaderboard"]
  const pageAreaManager  = ["/areamanager", "/areamanagerleaderboards", "/arearetailerleaderboard"]



  if (role === "A" && token && pageAdmin.includes(props.path)) {
    return <>{<Route {...props} />}</>;
  } else if (role === "W" && token && pageWholesaler.includes(props.path)) {
    return <>{<Route {...props} />}</>;
  } else if (role === "S" && token && pageSalesman.includes(props.path)) {
    return <>{<Route {...props} />}</>;
  } else if (role === "V" && token && pageSupervisor.includes(props.path)) {
    return <>{<Route {...props} />}</>; 
  } else if (role === "M" && token && pageAreaManager.includes(props.path)) {
    return <>{<Route {...props} />}</>;  
  } else if (role === "R" && token && pageRetailer.includes(props.path)) {
    return <>{<Route {...props} />}</>;  
  } else {
    localStorage.clear()
    return <>{<Redirect to="/login" />}</>;
  }
};

export default PrivateRoute;
