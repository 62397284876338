// import paths from "../../../ApiServices.json";
import paths from "../ApiServices.json"

const serverService = {
	user: "users",
	auth: "auth",
	content: "contentstatic",
	banner: "banner",
	newsfeed: "newsfeed",
	business: "businesses",
	area: "areas",
	catalogue: "catalogue",
	product: "product",
	order: "order",
	task: "task-management",
	coin: "coin-management",
	submission: "task-submission",
	raffle: "raffle",
	coupon: "coupon",
	cashierOrder: "cashier-order",
	default: "master",
	general: "general",
	controlTower: "control-tower"
};

const config = {
	api_url: paths.login.baseApiUrl,
    api_url_service: paths.login.serviceApiUrl,
    api_protocol: paths.login.apiProtocol,
    api_url_cf: paths.login.baseApiUrlCf
};

export {
    serverService,
    config
}