import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { formatPoint, formatPointMultiplier } from "./Popup";

const PopupRetailer = (props: any) => {
  const data = {
    employeeName: localStorage.getItem("nameSalesman"),
    employeeCode: localStorage.getItem("employeeCode"),
    retailerDetail: JSON.parse(
      localStorage.getItem("retailerDetail") ||
        '{"pointA":"senpai","pointB":"senpai","pointC":"senpai"}'
    ),
  };

  const safeFormatPoint = (point: any) => {
    return point ? formatPoint(point) : 0;
  };

  const safeReplaceDate = (date: any) => {
    return date ? date.replace("T00:00:00", "") : "N/A";
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Informasi Detail
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <tr>
            <td>Customer Code : {data.employeeCode}</td>
          </tr>
          <tr>
            <td>Retailer Name : {data.retailerDetail.retailerName}</td>
          </tr>
          <tr>
            <td>Group Name : {data.retailerDetail.groupName}</td>
          </tr>
          <tr>
            <td>SMP : {safeFormatPoint(data.retailerDetail.smp)}</td>
          </tr>
          <tr>
            <td>PVP : {safeFormatPoint(data.retailerDetail.pvp)}</td>
          </tr>
          <tr>
            <td>CPD : {safeFormatPoint(data.retailerDetail.cpd)}</td>
          </tr>
          <tr>
            <td>Cricket : {safeFormatPoint(data.retailerDetail.cricket)}</td>
          </tr>
          <tr>
            <td>DTE : {safeFormatPoint(data.retailerDetail.dte)}</td>
          </tr>
          <tr>
            <td>POSM : {safeFormatPoint(data.retailerDetail.posm)}</td>
          </tr>
          <tr>
            <td>
              Jumlah Minggu Aktif Digital :{" "}
              {safeFormatPoint(data.retailerDetail.b2B_CitaTransaction)}
            </td>
          </tr>
          <tr>
            <td>
              Multiplier :{" "}
              {data.retailerDetail.multiplier !== undefined
                ? formatPointMultiplier(data.retailerDetail.multiplier, true)
                : "N/A"}
            </td>
          </tr>
          <tr>
            <td>
              Base Point : {safeFormatPoint(data.retailerDetail.basePoint)}
            </td>
          </tr>
          <tr>
            <td>
              Total Point : {safeFormatPoint(data.retailerDetail.totalPoint)}
            </td>
          </tr>
          <tr>
            <td>
              Update Data Hingga Tanggal :{" "}
              {safeReplaceDate(data.retailerDetail.updateData)}
            </td>
          </tr>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="btn-warning text-center shadow-none"
          onClick={props.onHide}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PopupRetailer;
