import { FC, useState, useEffect } from "react";
import { RouteComponentProps, useLocation } from "react-router";
import axios from "axios";
import { Link } from "react-router-dom";
import paths from "../../../ApiServices.json";

import "./../../Style/leaderboard.css";
import Header from "../Header/Header";
import { Flip, toast, ToastContainer } from "react-toastify";
import { useForm } from "react-hook-form";
import { Spinner, Form } from "react-bootstrap";
import BasicPagination from "../../Pagination/BasicPagination";
import PaginationMessage from "../../Pagination/PaginationMessage";

type SomeComponentProps = RouteComponentProps;
const MaintainProfile: FC<SomeComponentProps> = ({ history }) => {
  const [urlResetAdmin, setUrlResetAdmin] = useState("");
  const [employeList, setEmployeeList] = useState<any[]>([]);
  const [filterEmployee, setfilterEmployee] = useState<any[]>([]);
  const [modalShow, setModalShow] = useState(false);
  const getUserList = paths.admin.getUserList;
  const getResetAdmin = paths.admin.resetAdmin;

  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(100); 
  const [totalData, setTotalData] = useState(0);
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [infoMessage, setInfoMessage] = useState('');
  const [searchText, setSearchText] = useState('');

  const getProfile = () => {
    const USER_TOKEN = localStorage.getItem("auth");
    const AuthStr = "Bearer " + USER_TOKEN;
    setLoading(true);
    axios
      .get(getUserList, {
        headers: { Authorization: AuthStr, "Access-Control-Allow-Origin": "*" },
      }
    )
      .then((res) => {
        const totalPages = Math.ceil(res.data.data.totalData / pageSize);
        const totalDatas = res.data.data.totalData;
        setEmployeeList(res.data.data.allUser);
        setfilterEmployee(res.data.data.allUser);
        setLoading(false);
        setTotalPages(totalPages);
        setTotalData(totalDatas);
      });
  };

  const getProfileSearch = () => {
    const USER_TOKEN = localStorage.getItem("auth");
    const AuthStr = "Bearer " + USER_TOKEN;
    setLoading(true);
    setInfoMessage(''); 
    axios
      .get(`${getUserList}?&searchText=${searchText}`, {
        headers: { Authorization: AuthStr, "Access-Control-Allow-Origin": "*" },
      }
    )
      .then((res) => {
        const totalPages = Math.ceil(res.data.data.totalData / pageSize);
        const totalDatas = res.data.data.totalData;
        setEmployeeList(res.data.data.allUser);
        setfilterEmployee(res.data.data.allUser);
        setLoading(false);
        setTotalPages(totalPages);
        setTotalData(totalDatas);
        setInfoMessage(''); 
      });
  };


  const indexOfLastItem = pageNumber * pageSize;
  const indexOfFirstItem = indexOfLastItem - pageSize;
  const currentItems = filterEmployee.slice(indexOfFirstItem, indexOfLastItem);

  
  let inputHandler = (e: any) => {
    const value = e.target.value || e.clipboardData.getData('text');
    if (value.length > 3) {
      setInfoMessage('Enter untuk Mencari'); 
      setSearchText(value); 
    } else if (value.length > 1) {
      setInfoMessage('Input setidaknya lebih dari 3 karakter');
      setSearchText('');
    }
    else  {
      setInfoMessage(''); 
      setSearchText('');
      getProfileSearch();
    }
  };

  const handleSearch = () => {
    if (searchText.length > 3) {
      getProfileSearch();
    }
  };

  const handleKeyDown = (event : any) => {
    if (event.key === 'Enter') {
      event.preventDefault(); 
      handleSearch(); 
    }
  };

  const getUrl = (customerCode: any) => {
    const USER_TOKEN = localStorage.getItem("auth");
    const AuthStr = "Bearer " + USER_TOKEN;
    setLoading(true);
    axios
      .put(
        getResetAdmin + customerCode,
        {},
        {
          headers: {
            Authorization: AuthStr,
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((res) => {
        toast.success(res.data.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: 0,
          toastId: "my_toast",
        });
        setLoading(false);
        setUrlResetAdmin(res.data.data.url);
        setModalShow(!modalShow);
      }).catch((error)=> {
        //handle error
        toast.error(error.response.data.message,{
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: 0,
                toastId: "my_toast",
        });
        setLoading(false);
      });
  };


  const handlePageChange = (page: number) => {
    setPageNumber(page);
  };

  useEffect(() => {
    getProfile();
  }, []);

  return (
    <>
      <Header />
      <div className="main-form">
        <div className="container">
          <div className="reward">
            <div className="row">
              <div
                className="col ms-1"
                style={{
                  paddingLeft: "0px",
                  fontSize: "larger",
                }}
              >
                <Link to="/admin">
                  <i className="fa fa-arrow-left m-3" />
                </Link>
                <label className="p-3" style={{ marginLeft: "-20px" }}>
                  <b>Atur Ulang Sandi</b>
                </label>
              </div>
            </div>

            <div className="search">
              <div className="input-group mb-2 mt-0">
                <Form.Control
                  type="text"
                  placeholder="Search"
                  onPaste={inputHandler}
                  onChange={inputHandler} 
                  onKeyDown={handleKeyDown}
                  />
              </div>
              {infoMessage && <p style={{ color: 'red' }}><small> {infoMessage}</small></p>} 
            </div>
            <div className="row mt-2">
              <div className="list" style={{ maxHeight: "600px", overflowY: "auto" }}>
                {loading ? (
                  <div className="text-center">
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  </div>
                ) : (
                  currentItems.map((employee) => (
                    <div key={employee.employeeCode}>
                      <div className="list-item-maintain">
                        <div className="col-2">
                          <p style={{ marginTop: "0px" }}>
                            <label>{employee.employeeCode}</label>
                          </p>
                        </div>
                        <div className="col-4 text-center">
                          <label>{employee.employeeName}</label>
                        </div>
                        <div className="col d-block text-end">
                          <a
                            onClick={() => {
                              getUrl(employee.employeeCode);
                              setModalShow(!modalShow);
                            }}
                            className="btn btn-oval"
                          >
                            Reset
                          </a>
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
          {<div
            className="pagination"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "6px",
            }}
          >
            <PaginationMessage
              pageNumber={pageNumber}
              pageSize={pageSize}
              totalData={totalData}
            />
            <BasicPagination
              currentPage={pageNumber}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
          </div>}
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover
        limit={1}
        transition={Flip}
      />
    </>
  );
};

export default MaintainProfile;
