import React, { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from "axios";
import { ToastContainer, toast, Flip } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { RouteComponentProps } from "react-router";
import paths from "../../../ApiServices.json";
// encrypt
import bcrypt from "bcryptjs";
// encrypt

import "./../../Style/header.css";
import "./../../Style/leaderboard.css";
import newmainlogo from "../../../Image/newmainlogo.png";
import { getProfileData, getToken } from "../../../Api/Endpoint";
import {
  getAuthorization,
  getProfile,
  setAuthorization,
  setProfile,
  setSSOLogin,
} from "../../../Helpers/Storage";

type SomeComponentProps = RouteComponentProps;
const Login: FC<SomeComponentProps> = ({ history }): JSX.Element => {
  const loginEmployee = paths.employee.login;
  const mappingLoginSSO = paths.employee.SSOLogin;
  const envLog = paths.login.envLog;
  const loginSSO = paths.login.loginSSO;
  const env = import.meta.url;
  const [profileData, setProfileDataState] = useState(getProfile());

  const link = process.env.REACT_APP_BASE_URL;
  const linkgabungan = link + loginEmployee;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [customerCode, setCustomerCode] = useState("");
  const [password, setPassword] = useState("");
  // hash pass
  const hashedPassword = bcrypt.hashSync(password, 10);

  const headerx = btoa(customerCode + ":" + password);
  const md5 = require("md5");

  // Login existing by customer code & password
  const login = () => {
    const dataLogin = {
      customerCode: customerCode,
      password: password,
    };

    axios
      .post(loginEmployee, null, {
        headers: { "x-token": headerx, "Access-Control-Allow-Origin": "*" },
      })
      .then((res) => {
        if (
          res.data.data.firstLogin === false &&
          res.data.data.employeePhone == false
        ) {
          localStorage.setItem(
            "cumulativePoint",
            res.data.data.mainData.kumulatif
          );
          localStorage.setItem(
            "employeeCode",
            res.data.data.mainData.employeeCode
          );
          localStorage.setItem(
            "employeeName",
            res.data.data.mainData.employeeName
          );
          localStorage.setItem(
            "employeeRole",
            res.data.data.mainData.employeeRole
          );
          localStorage.setItem("roleName", res.data.data.mainData.roleName);
          localStorage.setItem(
            "wholesellerDetail",
            res.data.data.mainData.wholesellerDetail
          );
          localStorage.setItem("groupName", res.data.data.mainData.groupName);
          localStorage.setItem(
            "groupNameTopCriket",
            res.data.data.mainData.groupNameTopCriket
          );
          localStorage.setItem(
            "groupNameTopDigital",
            res.data.data.mainData.groupNameTopDigital
          );

          var roleType = res.data.data.mainData.employeeRole;
          switch (roleType) {
            case "A":
              localStorage.setItem("auth", res.data.data.token);
              toast.success(res.data.message, {
                // Move toast.success here
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: 0,
                toastId: "my_toast",
              });
              setTimeout(() => {
                history.push("/admin");
              }, 3000);
              break;
            case "V":
              localStorage.setItem("auth", res.data.data.token);
              toast.success(res.data.message, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: 0,
                toastId: "my_toast",
              });
              setTimeout(() => {
                history.push("/supervisor");
              }, 3000);
              break;
            case "S":
              localStorage.setItem("auth", res.data.data.token);
              toast.success(res.data.message, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: 0,
                toastId: "my_toast",
              });
              setTimeout(() => {
                history.push("/salesman");
              }, 3000);
              break;
            case "M":
              localStorage.setItem("auth", res.data.data.token);
              toast.success(res.data.message, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: 0,
                toastId: "my_toast",
              });
              setTimeout(() => {
                history.push("/areamanager");
              }, 3000);
              break;
            case "W":
              localStorage.setItem("auth", res.data.data.token);
              localStorage.setItem(
                "wholesellerCode",
                res.data.data.mainData.employeeCode
              );
              toast.success(res.data.message, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: 0,
                toastId: "my_toast",
              });
              setTimeout(() => {
                history.push("/wholeseller");
              }, 3000);
              break;
            case "R":
              localStorage.setItem("auth", res.data.data.token);
              localStorage.setItem(
                "wholesellerCode",
                res.data.data.mainData.employeeCode
              );
              toast.success(res.data.message, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: 0,
                toastId: "my_toast",
              });
              setTimeout(() => {
                history.push("/retailer");
              }, 3000);
              break;
          }
          return;
        } else if (
          res.data.data.firstLogin === false &&
          res.data.data.employeePhone == true
        ) {
          localStorage.setItem("customerCode", customerCode);
          toast.success(res.data.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: 0,
            toastId: "my_toast",
          });
          setTimeout(() => {
            history.push("/updatephonenumber");
          }, 3000);
        } else {
          localStorage.setItem("customerCode", customerCode);
          toast.success(res.data.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: 0,
            toastId: "my_toast",
          });
          setTimeout(() => {
            history.push("/firstlogin");
          }, 3000);
        }
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          toast.error(error.response.data.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: 0,
            toastId: "my_toast",
          });
        } else {
          toast.error("An error occurred", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: 0,
            toastId: "my_toast",
          });
        }
      });
  };

  // Set token after login sso
  const handleSetToken = async (code: any) => {
    try {
      const env = envLog === "LOCAL" ? 2 : 3;
      const token = await getToken(code, env);
      if (token === "inactive") {
        history.push("/404Page");
      }
      if (token) {
        setAuthorization(token);
        const dataProfile = await getProfileData();
        if (setProfile(dataProfile)) {
          setProfileDataState(dataProfile); // Update profileData state
          const headerSSO = btoa(dataProfile.username); // Use dataProfile directly
          axios
            .post(mappingLoginSSO, null, {
              headers: {
                "x-token": headerSSO,
                "Access-Control-Allow-Origin": "*",
              },
            })
            .then((res) => {
              if (res.data.data.token) {
                localStorage.setItem(
                  "cumulativePoint",
                  res.data.data.mainData.kumulatif
                );
                localStorage.setItem(
                  "employeeCode",
                  res.data.data.mainData.employeeCode
                );
                localStorage.setItem(
                  "employeeName",
                  res.data.data.mainData.employeeName
                );
                localStorage.setItem(
                  "employeeRole",
                  res.data.data.mainData.employeeRole
                );
                localStorage.setItem(
                  "roleName",
                  res.data.data.mainData.roleName
                );
                localStorage.setItem(
                  "wholesellerDetail",
                  res.data.data.mainData.wholesellerDetail
                );
                localStorage.setItem(
                  "groupName",
                  res.data.data.mainData.groupName
                );
                var roleType = res.data.data.mainData.employeeRole;
                switch (roleType) {
                  case "A":
                    localStorage.setItem("auth", res.data.data.token);
                    toast.success(res.data.message, {
                      position: "top-right",
                      autoClose: 3000,
                      hideProgressBar: true,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: false,
                      progress: 0,
                      toastId: "my_toast",
                    });
                    setTimeout(() => {
                      history.push("/admin");
                    }, 3000);
                    break;
                  case "V":
                    localStorage.setItem("auth", res.data.data.token);
                    toast.success(res.data.message, {
                      position: "top-right",
                      autoClose: 3000,
                      hideProgressBar: true,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: false,
                      progress: 0,
                      toastId: "my_toast",
                    });
                    setTimeout(() => {
                      history.push("/supervisor");
                    }, 3000);
                    break;
                  case "S":
                    localStorage.setItem("auth", res.data.data.token);
                    toast.success(res.data.message, {
                      position: "top-right",
                      autoClose: 3000,
                      hideProgressBar: true,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: false,
                      progress: 0,
                      toastId: "my_toast",
                    });
                    setTimeout(() => {
                      history.push("/salesman");
                    }, 3000);
                    break;
                  case "M":
                    localStorage.setItem("auth", res.data.data.token);
                    toast.success(res.data.message, {
                      position: "top-right",
                      autoClose: 3000,
                      hideProgressBar: true,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: false,
                      progress: 0,
                      toastId: "my_toast",
                    });
                    setTimeout(() => {
                      history.push("/areamanager");
                    }, 3000);
                    break;
                  case "W":
                    localStorage.setItem("auth", res.data.data.token);
                    localStorage.setItem(
                      "wholesellerCode",
                      res.data.data.mainData.employeeCode
                    );
                    toast.success(res.data.message, {
                      position: "top-right",
                      autoClose: 3000,
                      hideProgressBar: true,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: false,
                      progress: 0,
                      toastId: "my_toast",
                    });
                    setTimeout(() => {
                      history.push("/wholeseller");
                    }, 3000);
                    break;
                  case "R":
                    localStorage.setItem("auth", res.data.data.token);
                    localStorage.setItem(
                      "wholesellerCode",
                      res.data.data.mainData.employeeCode
                    );
                    toast.success(res.data.message, {
                      position: "top-right",
                      autoClose: 3000,
                      hideProgressBar: true,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: false,
                      progress: 0,
                      toastId: "my_toast",
                    });
                    setTimeout(() => {
                      history.push("/retailer");
                    }, 3000);
                    break;
                }
              } else {
                localStorage.setItem("customerCode", customerCode);
                toast.success(res.data.message, {
                  position: "top-right",
                  autoClose: 3000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: false,
                  progress: 0,
                  toastId: "my_toast",
                });
              }
            });
        } else {
          history.push("/404Page");
        }
      }
    } catch (error) {
      //console.log(error);
      history.push("/404Page");
    }
  };

  const redirectToCognito = () => {
    window.location.href = loginSSO;
  };

  const handleLoginSSO = () => {
    try {
      const code = setSSOLogin();
      return code;
    } catch (error) {
      //console.log(error);
    }
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const code = searchParams.get("code");
    if (code) {
      handleSetToken(code);
    }
  }, [history]);

  return (
    <>
      <div className="text-center bg-main">
        <img
          className="img-fluid"
          src={newmainlogo}
          width="150"
          style={{
            marginTop: "100px",
            marginBottom: "125px",
          }}
        />
      </div>
      <div className="main-form">
        <div className="login p-5 container">
          <h3>Selamat datang di</h3>
          <h1>Liga Mahakarya</h1>
          <p>
            <form autoComplete="off" onSubmit={handleSubmit(login)}>
              <div className="mb-3">
                <label className="form-label">Kode Pelanggan</label>
                <input
                  type="text"
                  className="form-control"
                  {...register("customercode", {
                    required: "Customer code harap di isi !",
                  })}
                  onChange={(e) => setCustomerCode(e.target.value)}
                />
                {errors.customercode && (
                  <p className="text-danger" style={{ fontSize: 14 }}>
                    {errors.customercode.message}
                  </p>
                )}
              </div>
              <div className="mb-3">
                <label className="form-label">
                  Kata Sandi *password case sensitive
                </label>
                <input
                  type="password"
                  className="form-control"
                  {...register("password", {
                    required: "Password harap di isi !",
                  })}
                  onChange={(e) => setPassword(e.target.value)}
                />
                {errors.password && (
                  <p className="text-danger" style={{ fontSize: 14 }}>
                    {errors.password.message}
                  </p>
                )}
              </div>
              <div className="d-grid gap-4 mt-5">
                <button
                  className="btn btn-warning text-center shadow-none mb-3"
                  type="button"
                  onClick={handleSubmit(login)}
                >
                  Masuk
                </button>
              </div>
            </form>
            <div className="d-grid gap-4 mt-5">
              <Link to={"/forgotpassword"} className="text-center">
                <button className="btn btn-link">Lupa Kata Sandi ?</button>
              </Link>
              {/* <a href={loginSSO} className="text-left mb-5">
                <button className="btn btn-link">SSO Login</button>
              </a> */}
            </div>
          </p>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover
        limit={1}
        transition={Flip}
      />
    </>
  );
};
export default Login;
