import { FC, useState } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from "axios";
import { ToastContainer, toast, Flip } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css"
import { RouteComponentProps, useHistory, useLocation } from "react-router-dom";

import paths from "../../../ApiServices.json";




import "./../../Style/leaderboard.css"
import newmainlogo from "../../../Image/newmainlogo.png";
import ClipLoader from "react-spinners/ClipLoader";

type SomeComponentProps = RouteComponentProps;
const PhoneNumber: FC<SomeComponentProps> = ({ history }): JSX.Element => {
const [loading, setLoading] = useState(false);
const nomorHP =  paths.general.updatePhoneNumber;


    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const [customerCode, setCustomerCode] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")

    

    const Update = () => {
        const phoneUpdate = {
            customerCode: localStorage.getItem("customerCode"),
            employeePhone: phoneNumber
        };
        setLoading(true);
        axios
            .put(nomorHP, phoneUpdate,{headers: {"Access-Control-Allow-Origin":"*"}})
            .then((res) => {
                toast.success(res.data.message, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: 0,
                toastId: "my_toast",
              });
              setLoading(false);
              setTimeout(() => {
                localStorage.clear();
                history.push("/");
            }, 3000);
              
            }).catch((error)=> {
              //handle error
              toast.error(error.response.data.message,{
                      position: "top-right",
                      autoClose: 3000,
                      hideProgressBar: true,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: false,
                      progress: 0,
                      toastId: "my_toast",
              })});

        };

        if(loading == true ){
          return(
            <div className="Loadinggg">
            <ClipLoader
            size={30}
            color={"#123abc"}
            />
            Now Loading...
            </div>
          )
        }   
        return(
            <>
            <div className="text-center bg-main">
                <img className="img-fluid" src={newmainlogo} width="150" 
                    style={{
                        marginTop: "100px", 
                        marginBottom: "125px"}} />
            </div>
            <div className="main-form">
          <div className="login p-5 container">
              <h1>Update Phone Number</h1>
                <p>
                <form autoComplete="off" onSubmit={handleSubmit(Update)}>

                {/* <div className="mb-3">
                    <label className="form-label">Kode Pelanggan</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("customercode", { required: "Kode Pelanggan harap di isi !" })}
                      onChange={(e) => setCustomerCode(e.target.value)}
                    />
                    {errors.customercode && (
                      <p className="text-danger" style={{ fontSize: 14 }}>
                        {errors.customercode.message}
                      </p>
                    )}
                  </div> */}

                  
                  <div className="mb-3">
                    <label className="form-label">Nomor Handphone</label>
                    <input
                      type="number"
                      className="form-control"
                      {...register("phonenumber", { required: "Nomor Handphone harap di isi !" })}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                    />
                    {errors.phonenumber && (
                      <p className="text-danger" style={{ fontSize: 14 }}>
                        {errors.phonenumber.message}
                      </p>
                    )}
                  </div>


                  <div className="d-grid gap-4 mt-5">
                    <button
                      className="btn btn-warning text-center shadow-none mb-3"
                      type="button" 
                      onClick={handleSubmit(Update)}
                    >
                      Update Nomor
                    </button>
                    
                    
                    <Link to={"/"} className="text-center">        
                      <button className="btn btn-link" >
                        Batal
                      </button>
                      </Link>
                  </div>
                  

                </form>
                </p>
              </div>
            </div>
            <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar
            closeOnClick
            rtl={false}
            pauseOnFocusLoss={false}
            draggable={false}
            pauseOnHover
            limit={1}
            transition={Flip}
            />
        </>
    )        
}

    export default PhoneNumber
