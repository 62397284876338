import { FC, useEffect, useState } from "react";
import { RouteComponentProps } from "react-router";
import paths from "../../../ApiServices.json";
import { getProfileData, getToken } from "../../../Api/Endpoint";
import {
  getAuthorization,
  getProfile,
  setAuthorization,
  setProfile,
  setSSOLogin,
} from "../../../Helpers/Storage";
import axios from "axios";
import { ToastContainer, toast, Flip } from "react-toastify";

type SomeComponentProps = RouteComponentProps;

const LoginInternalSSO: FC<SomeComponentProps> = ({ history }): JSX.Element => {
  const [loading, setLoading] = useState(true);
  const loginSSO = paths.login.loginSSO;
  const envLog = paths.login.envLog;
  const mappingLoginSSO = paths.employee.SSOLogin;
  const [profileData, setProfileDataState] = useState(getProfile());
  const [customerCode, setCustomerCode] = useState("");

  const handleSetToken = async (code: any) => {
    try {
      const env = envLog === "LOCAL" ? 2 : 3;
      const token = await getToken(code, env);
      if (token === "inactive") {
        history.push("/404Page");
      }
      if (token) {
        setAuthorization(token);
        const dataProfile = await getProfileData();
        if (setProfile(dataProfile)) {
          setProfileDataState(dataProfile); // Update profileData state
          const headerSSO = btoa(dataProfile.username); // Use dataProfile directly
          axios
            .post(mappingLoginSSO, null, {
              headers: {
                "x-token": headerSSO,
                "Access-Control-Allow-Origin": "*",
              },
            })
            .then((res) => {
              if (res.data.data.token) {
                localStorage.setItem(
                  "cumulativePoint",
                  res.data.data.mainData.kumulatif
                );
                localStorage.setItem(
                  "employeeCode",
                  res.data.data.mainData.employeeCode
                );
                localStorage.setItem(
                  "employeeName",
                  res.data.data.mainData.employeeName
                );
                localStorage.setItem(
                  "employeeRole",
                  res.data.data.mainData.employeeRole
                );
                localStorage.setItem(
                  "roleName",
                  res.data.data.mainData.roleName
                );
                localStorage.setItem(
                  "wholesellerDetail",
                  res.data.data.mainData.wholesellerDetail
                );
                localStorage.setItem(
                  "groupName",
                  res.data.data.mainData.groupName
                );
                var roleType = res.data.data.mainData.employeeRole;
                switch (roleType) {
                  case "A":
                    localStorage.setItem("auth", res.data.data.token);
                    toast.success(res.data.message, {
                      position: "top-right",
                      autoClose: 3000,
                      hideProgressBar: true,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: false,
                      progress: 0,
                      toastId: "my_toast",
                    });
                    setTimeout(() => {
                      history.push("/admin");
                    }, 3000);
                    break;
                  case "V":
                    localStorage.setItem("auth", res.data.data.token);
                    toast.success(res.data.message, {
                      position: "top-right",
                      autoClose: 3000,
                      hideProgressBar: true,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: false,
                      progress: 0,
                      toastId: "my_toast",
                    });
                    setTimeout(() => {
                      history.push("/supervisor");
                    }, 3000);
                    break;
                  case "S":
                    localStorage.setItem("auth", res.data.data.token);
                    toast.success(res.data.message, {
                      position: "top-right",
                      autoClose: 3000,
                      hideProgressBar: true,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: false,
                      progress: 0,
                      toastId: "my_toast",
                    });
                    setTimeout(() => {
                      history.push("/salesman");
                    }, 3000);
                    break;
                  case "M":
                    localStorage.setItem("auth", res.data.data.token);
                    toast.success(res.data.message, {
                      position: "top-right",
                      autoClose: 3000,
                      hideProgressBar: true,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: false,
                      progress: 0,
                      toastId: "my_toast",
                    });
                    setTimeout(() => {
                      history.push("/areamanager");
                    }, 3000);
                    break;
                  case "W":
                    localStorage.setItem("auth", res.data.data.token);
                    localStorage.setItem(
                      "wholesellerCode",
                      res.data.data.mainData.employeeCode
                    );
                    toast.success(res.data.message, {
                      position: "top-right",
                      autoClose: 3000,
                      hideProgressBar: true,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: false,
                      progress: 0,
                      toastId: "my_toast",
                    });
                    setTimeout(() => {
                      history.push("/wholeseller");
                    }, 3000);
                    break;
                }
              } else {
                localStorage.setItem("customerCode", customerCode);
                toast.success(res.data.message, {
                  position: "top-right",
                  autoClose: 3000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: false,
                  progress: 0,
                  toastId: "my_toast",
                });
              }
            });
        } else {
          history.push("/404Page");
        }
      }
    } catch (error) {
      // console.log(error);
      history.push("/404Page");
    }
  };

  const redirectToCognito = () => {
    window.location.href = loginSSO;
  };

  const handleLoginSSO = () => {
    try {
      const code = setSSOLogin();
      return code;
    } catch (error) {
      // console.log(error);
    }
  };

  useEffect(() => {
    if (getAuthorization()) {
      // return navigate(objectRouter.home.path)
    }
    const code = handleLoginSSO();
    if (code) {
      handleSetToken(code);
    } else {
      redirectToCognito();
    }
  }, []);

  return (
    <div>
      <p>Loading ...</p>
    </div>
  );
};

export default LoginInternalSSO;
