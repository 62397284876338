import React from "react";
import { Tabs, Tab, Container } from "react-bootstrap";
import "./../Style/leaderboard.css"
import LeaderboardTable from "../Leaderboard/leaderboardTable";
import TopCricketTable from "../TopCricket/TopCricketPerformance";
import TopDigitalTable from "../TopDigital/TopDigitalPerformance";

interface TabsComponentProps {
  wholesellerCode: string;
  roundId?: number;
  value: string;
  setValue: (value: string) => void;
  updateGroupName: (groupName: string) => void;
}

const TabsComponent: React.FC<TabsComponentProps> = ({
  wholesellerCode,
  roundId,
  value,
  setValue,
  updateGroupName
}) => {
  const round = localStorage.getItem("roundId");
  const effectiveRoundId = roundId ?? (round ? parseInt(round) : undefined);
  const groupName = localStorage.getItem("groupName") || "";
  const groupNameTopCriket = localStorage.getItem("groupNameTopCriket") || "";

  const groupNameTopDigital = localStorage.getItem("groupNameTopDigital") || "";

  const handleSelect = (newValue: string | null) => {
    if (newValue) {
      setValue(newValue);

      // Update groupName in header based on tab clicked
      if (newValue === "1") {
        updateGroupName(groupName);
      } else if (newValue === "2") {
        updateGroupName(groupNameTopCriket);
      } else if (newValue === "3") {
        updateGroupName(groupNameTopDigital);
      }
    }
  };

  React.useEffect(() => {
    // Initial update on load
    if (value === "1") {
      updateGroupName(groupName);
    } else if (value === "2") {
      updateGroupName(groupNameTopCriket);
    } else if (value === "3") {
      updateGroupName(groupNameTopDigital);
    }
  }, [value, groupName, groupNameTopCriket, groupNameTopDigital, updateGroupName]);

  React.useEffect(() => {
    if (value === "1") {
      updateGroupName(groupName);
    }
  }, [groupName]);

  return (
    <Container>
      <Tabs
        id="fill-tab-example"
        activeKey={value}
        onSelect={handleSelect}
        className="mb-3 custom-tab"
        fill
      >
        <Tab eventKey="1" title="Klasemen Utama">
          <LeaderboardTable wholesellerCode={wholesellerCode} groupName={groupName} />
        </Tab>
        <Tab eventKey="2" title="Top Cricket Performance">
          <TopCricketTable
            wholesellerCode={wholesellerCode}
            groupName={groupNameTopCriket}
            roundId={effectiveRoundId}
            scrollToEntry={value === "2"}
          />
        </Tab>
        <Tab eventKey="3" title="Top Digital Performance">
          <TopDigitalTable
            wholesellerCode={wholesellerCode}
            groupName={groupNameTopDigital}
            roundId={effectiveRoundId}
            scrollToEntry={value === "3"}
          />
        </Tab>
      </Tabs>
    </Container>
  );
};

export default TabsComponent;
