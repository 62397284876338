// Import yang diperlukan
import React, { FC } from "react";
import { Pagination } from "react-bootstrap";
import "./../Style/leaderboard.css"

// Definisikan properti yang diterima oleh CustomPagination
interface BasicPaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (pageNumber: number) => void;
}

// Komponen CustomPagination
const BasicPagination: FC<BasicPaginationProps> = ({
  currentPage,
  totalPages,
  onPageChange,
}) => {
  const handlePageChange = (pageNumber: number) => {
    if (pageNumber !== currentPage) {
      onPageChange(pageNumber);
    }
  };

  const paginationItems = [];

  // Menambahkan halaman pertama
  paginationItems.push(
    <Pagination.Item
      key={1}
      active={1 === currentPage}
      onClick={() => handlePageChange(1)}
    >
      1
    </Pagination.Item>
  );

  // Menambahkan ellipsis jika halaman saat ini lebih besar dari 3
  if (currentPage > 3) {
    paginationItems.push(<Pagination.Ellipsis key="start-ellipsis" />);
  }

  // Menambahkan halaman di sekitar halaman saat ini
  for (let number = Math.max(2, currentPage - 1); number <= Math.min(totalPages - 1, currentPage + 1); number++) {
    paginationItems.push(
      <Pagination.Item
        key={number}
        active={number === currentPage}
        onClick={() => handlePageChange(number)}
      >
        {number}
      </Pagination.Item>
    );
  }

  // Menambahkan ellipsis jika halaman saat ini lebih kecil dari total halaman - 2
  if (currentPage < totalPages - 2) {
    paginationItems.push(<Pagination.Ellipsis key="end-ellipsis" />);
  }

  // Menambahkan halaman terakhir
  if (totalPages > 1) {
    paginationItems.push(
      <Pagination.Item
        key={totalPages}
        active={totalPages === currentPage}
        onClick={() => handlePageChange(totalPages)}
      >
        {totalPages}
      </Pagination.Item>
    );
  }

  return (
    <Pagination className="mt-3 custom-pagination">
      <Pagination.First onClick={() => handlePageChange(1)} disabled={currentPage === 1} />
      <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
      {paginationItems}
      <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
      <Pagination.Last onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages} />
    </Pagination>
  );
};

export default BasicPagination;
