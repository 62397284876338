import { serverService } from "../Helpers/Config";
import request from "../Helpers/TokenApiSso";

const dataServerService = serverService

const getToken = (param: any, env: any) => {
	return request({
		url: `/oauth/cognito/field-force?code=${param}&is_stock_rec=${env}`,
		method: "post",
		endpoint_type: dataServerService.user
	},
	null);
};

const getTerritory = () => {
	return request({
		url: "/api/stock-rec/list-territory",
		method: "Get",
		endpoint_type: dataServerService.controlTower
	})
}

const getRetailerList = (param: any) => {
	return request({
		url: "/api/stock-rec/list-retailer",
		method: "Post",
		data: param,
		endpoint_type: dataServerService.controlTower
	})
}

const getProfileData = () => {
	return request({
		url: "/oauth/user",
		method: "Get",
		endpoint_type: dataServerService.user
	})
}

const getLasOrder = (params: any) => {
	return request({
		url: `/api/stock-rec/last-order/${params.id}`,
		method : "Get",
		endpoint_type: dataServerService.controlTower
	})
}

const getAvailShipping =  (params:  any) => {
	return request({
		url : `/api/stock-rec/avail-ship-type`,
		method : "Post",
		data : params,
		endpoint_type : dataServerService.controlTower
	})
}

const getRecommendation = (data: any) => {
	return request({
		url: "/api/stock-rec/product-rec",
		method : "Post",
		data: data,
		endpoint_type : dataServerService.controlTower
	})
}

const orderCita = (data: any) => {
	return request({
		url: "/api/control-tower/orders-cita/store",
		method: "Post",
		data: data,
		endpoint_type: dataServerService.controlTower
	})
}


export {
    getToken,
	getTerritory,
	getRetailerList,
	getProfileData,
	getLasOrder,
	getAvailShipping,
	getRecommendation,
	orderCita
}