import React, { FC, useEffect, useState } from "react";
import { RouteComponentProps } from "react-router";
import axios from "axios";
import { Link } from "react-router-dom";
import HeaderSM from "../Header/HeaderSM";
import "./../../Style/leaderboard.css";
import paths from "../../../ApiServices.json";
import BasicPagination from "../../Pagination/BasicPagination";
import PaginationMessage from "../../Pagination/PaginationMessage";
import { Spinner, Form } from "react-bootstrap";

type SomeComponentProps = RouteComponentProps;
const WholesalerNational: FC<SomeComponentProps> = ({ history }) => {
  const USER_TOKEN = localStorage.getItem("auth");
  const AuthStr = "Bearer " + USER_TOKEN;
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(100); 
  const [totalData, setTotalData] = useState(0);
  const [searchText, setSearchText] = useState('');
  const [loading, setLoading] = useState(true);
  const [infoMessage, setInfoMessage] = useState('');

  const detailRound = paths.employee.getRoundDetails;
  const getMain = paths.employee.getMain;

  const [whosellerList, setWholeSellerList] = useState<any[]>([]);
  const [roundDetail, setRoundDetail] = useState({
    currentRoundId: 0,
    currentRoundName: "",
  });
  const [filterEmployee, setfilterEmployee] = useState<any[]>([]);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [groupName, setGroupName] = useState("");

  let inputHandler = (e: any) => {
    const value = e.target.value || e.clipboardData.getData('text');
    if (value.length > 3) {
      setInfoMessage('Enter untuk Mencari'); 
      setSearchText(value); 
    } else if (value.length > 1) {
      setInfoMessage('Input setidaknya lebih dari 3 karakter');
      setSearchText('');
    }
    else  {
      setInfoMessage(''); 
      setSearchText('');
      GetWholeSellerList();
    }
  };

  const handleSearch = () => {
    if (searchText.length > 3) {
      GetWholeSellerListSearch();
    }
  };

  const handleKeyDown = (event : any) => {
    if (event.key === 'Enter') {
      event.preventDefault(); 
      handleSearch(); 
    }
  };
  
  const indexOfLastItem = pageNumber * pageSize;
  const indexOfFirstItem = indexOfLastItem - pageSize;
  const currentItems = filterEmployee.slice(indexOfFirstItem, indexOfLastItem);

  const user = {
    employeeCode: localStorage.getItem("employeeCode"),
  };

  const round = () => {
    axios
      .get(detailRound, {
        headers: { Authorization: AuthStr, "Access-Control-Allow-Origin": "*" },
      })
      .then((res) => {
        setRoundDetail(res.data.data);
        localStorage.setItem("roundId", res.data.data.currentRoundId);
        localStorage.setItem("roundName", res.data.data.currentRoundName);
        setRoundDetail({
          currentRoundId: res.data.data.currentRoundId,
          currentRoundName: res.data.data.currentRoundName,
        });
      });
  };

  const userSales = {
    employeeCode: localStorage.getItem("employeeCode"),
  };

  const GetWholeSellerList = () => {
    axios
      .get(
        getMain + user.employeeCode, {headers: {Authorization: AuthStr,"Access-Control-Allow-Origin":"*"}}
      )
      .then((res) => {
        const totalPages = Math.ceil(res.data.data.totalData / pageSize);
        const totalDatas = res.data.data.totalData;
        setWholeSellerList(res.data.data.wholesellers);
        localStorage.setItem("salesmanName", res.data.data.employeeName);
        localStorage.setItem("salesmanDetail", res.data.data.wholesellers);
        setfilterEmployee(res.data.data.wholesellers);
        setLoading(false);
        setTotalPages(totalPages);
        setTotalData(totalDatas);
      });
  };

  const GetWholeSellerListSearch = () => {
    setLoading(true);
    setInfoMessage(''); 
    axios
      .get(
        `${getMain}${user.employeeCode}&searchText=${searchText}`,
        {
          headers: {
            Authorization: AuthStr,
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((res) => {
        const totalPages = Math.ceil(res.data.data.totalData / pageSize);
        const totalDatas = res.data.data.totalData;
        setWholeSellerList(res.data.data.wholesellers);
        localStorage.setItem("salesmanName", res.data.data.employeeName);
        localStorage.setItem("salesmanDetail", res.data.data.wholesellers);
        setfilterEmployee(res.data.data.wholesellers);
        setLoading(false);
        setTotalPages(totalPages);
        setTotalData(totalDatas);
        setInfoMessage("")
      });
  };

  const detail = (selectedWs: string, wholeseller: any, selectedNameWs: string) => {
    const groupNameLeader = getGroupName(wholeseller);
    localStorage.setItem("wholesellerCode", selectedWs);
    localStorage.setItem("groupName", groupNameLeader);
    localStorage.setItem("nameSalesman", selectedNameWs);
  
    const targetRoute =
      wholeseller.roleType === "R"
        ? "/retailernationalleaderboard"
        : "/wholesalernationalleaderboard";
    history.push(targetRoute, wholeseller);
  };

  const handlePageChange = (page: number) => {
    setPageNumber(page);
  };

  useEffect(() => {
    round();
    GetWholeSellerList();
  }, []);

  const stat: any = history.location.state;

  const getGroupName = (wholeseller: any) => {
    if (wholeseller.groupName) {
      return wholeseller.groupName;
    } else if (wholeseller.groupNameTopCriket) {
      return wholeseller.groupNameTopCriket;
    } else if (wholeseller.groupNameTopDigital) {
      return wholeseller.groupNameTopDigital;
    } else {
      return "None";
    }
  };

  return (
    <>
      <HeaderSM />
      <div className="main-form">
        <div className="container">
          <div
            className="col ms-1"
            style={{ paddingLeft: "0px", fontSize: "larger" }}
          >
            <Link to="/admin">
              <i className="fa fa-arrow-left m-3" />
            </Link>
            <label className="p-3" style={{ marginLeft: "-20px" }}>
              <b>Semua Wholesaler</b>
            </label>
          </div>
          <div className="search">
            <div className="input-group mb-2 mt-0">
              <Form.Control
                  type="text"
                  placeholder="Search"
                  onPaste={inputHandler}
                  onChange={inputHandler} 
                  onKeyDown={handleKeyDown}
                />
            </div>
            {infoMessage && <p style={{ color: 'red' }}><small> {infoMessage}</small></p>} 
          </div>
          <div className="reward">
            <div className="row">
              {roundDetail && roundDetail.currentRoundId !== 0 && (
                <div key={roundDetail.currentRoundId}>
                  <div className="col ms-1">
                    <label>
                      Saat ini sedang dalam{" "}
                      <b>{roundDetail.currentRoundName}</b>
                    </label>
                  </div>
                </div>
              )}
              <div className="row mt-1">
                <div
                  className="list"
                  style={{
                    maxHeight: "300px",
                    overflowY: "auto",
                    overflowX: "hidden",
                  }}
                >
                  {loading ? (
                    <div className="text-center">
                      <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    </div>
                  ) : currentItems && currentItems.length > 0 ? (
                    currentItems.map((Wholeseller) => (
                      <div key={Wholeseller.wholesellerCode}>
                        <div className="list-item">
                          <div className="col-2">
                            <span> Grup </span>
                            <label>{getGroupName(Wholeseller)}</label>
                          </div>
                          <div className="col-4">
                            <span> Kode WS </span>
                            <label>{Wholeseller.wholesellerCode}</label>
                          </div>
                          <div className="col-4">
                            <span>Nama WS</span>
                            <label>{Wholeseller.wholesellerName}</label>
                          </div>
                          <div className="col d-block text-end">
                            <a
                              className="btn btn-circle"
                              onClick={(e) =>
                                detail(Wholeseller.wholesellerCode, Wholeseller, Wholeseller.wholesellerName)
                              }
                            >
                              <i className="fa fa-arrow-right text-light"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="text-center">Data Tidak Ditemukan</div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {<div
            className="pagination"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "6px",
            }}
          >
            <PaginationMessage
              pageNumber={pageNumber}
              pageSize={pageSize}
              totalData={totalData}
            />
            <BasicPagination
              currentPage={pageNumber}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
          </div>}
        </div>
      </div>
    </>
  );
};

export default WholesalerNational;
