import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const Popup = (props: any) => {
  const data = {
    employeeName: localStorage.getItem("employeeName"),
    wholesellerDetail: JSON.parse(
      localStorage.getItem("wholesellerDetail") ||
        '{"pointA":"senpai","pointB":"senpai","pointC":"senpai"}'
    ),
  };

  const safeFormatPoint = (point: any) => {
    return point ? formatPoint(point) : 0;
  };

  const safeReplaceDate = (date: any) => {
    return date ? date.replace("T00:00:00", "") : "N/A";
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Informasi Detail
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <tr>
            <td>Wholesaler Name : {data.employeeName}</td>
          </tr>
          <tr>
            <td>Point SMP : {safeFormatPoint(data.wholesellerDetail.pointB)}</td>
          </tr>
          <tr>
            <td>
              Point Multicategory & Gamification :{" "}
              {safeFormatPoint(data.wholesellerDetail.pointA)}
            </td>
          </tr>
          <tr>
            <td>
              Multiplier :{" "}
              {data.wholesellerDetail.multiplier !== undefined
                ? formatPointMultiplier(data.wholesellerDetail.multiplier, true)
                : "N/A"}
            </td>
          </tr>
          <tr>
            <td>
              Total Point : {safeFormatPoint(data.wholesellerDetail.salePoint)}
            </td>
          </tr>
          <tr>
            <td>
              Point Tahunan : {safeFormatPoint(data.wholesellerDetail.kumulatif)}
            </td>
          </tr>
          <tr>
            <td>
              Target Stock Mingguan :{" "}
              {safeFormatPoint(data.wholesellerDetail.baselineStock)}
            </td>
          </tr>
          <tr>
            <td>
              Target Private Label Es.Er.Ce Round Ini:{" "}
              {safeFormatPoint(data.wholesellerDetail.targetPrivate)}
            </td>
          </tr>
          <tr>
            <td>
              Update Data Hingga Tanggal :{" "}
              {safeReplaceDate(data.wholesellerDetail.updateData)}
            </td>
          </tr>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

export const formatPoint = (e: any) => {
  return e.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

export const formatPointMultiplier = (value: number, isMultiplier = false) => {
  if (isMultiplier) {
    const decimalValue = (value / 10000).toFixed(2);
    return decimalValue.replace(".", ",");
  } else {
    const formattedValue = (value / 100).toLocaleString("id-ID", {
      minimumFractionDigits: 2,
    });
    return formattedValue;
  }
};

export default Popup;