// LeaderboardTable.tsx
import React, { FC, Fragment, useEffect, useRef, useState } from "react";
import axios from "axios";
import { formatPoint, formatPointMultiplier } from "../PopUpComponents/Popup";
import "../Style/leaderboard.css";
import paths from "../../ApiServices.json";

interface LeaderboardTableProps {
  wholesellerCode: string;
  groupName: string;
}

const LeaderboardTable: FC<LeaderboardTableProps> = ({ wholesellerCode, groupName }) => {
  // constanta endpoint get round details
  const detailRound = paths.employee.getRoundDetails;
  const setGroupNameTopCricket = localStorage.getItem("groupNameTopCriket")
  // constanta endpoint get main menu data api
  const getMain = paths.employee.getMain;

  // constanta endpoint get leaderboard data api
  const getLeaderboard = paths.employee.getLeaderboard;
  
  // state roundname
  const [roundName, setRoundName] = useState(0);

  // state rounddetail
  const [roundDetail, setRoundDetail] = useState({
    currentRoundId: 0,
    currentRoundName: "",
  });

  // state wholesellerdetail
  const [wholesellerDetail, setWholesellerDetail] = useState<any[]>([]);

  const [leaderboards, setLeaderboards] = useState<any[]>([]);
  const rowRefs = useRef<(HTMLTableRowElement | null)[]>([]);

  const USER_TOKEN = localStorage.getItem("auth");
  const AuthStr = "Bearer " + USER_TOKEN;

  // constanta untuk dapetin wholeseller code dari local storage
  const wholeseller = {
    wholesellerCode: localStorage.getItem("wholesellerCode"),
  };

  // Function get detail round api
  const round = async () => {
    await axios
      .get(detailRound, {
        headers: { Authorization: AuthStr, "Access-Control-Allow-Origin": "*" },
      })
      .then((res) => {
        setRoundDetail(res.data.data);
        localStorage.setItem("roundId", res.data.data.currentRoundId);
        localStorage.setItem("roundName", res.data.data.currentRoundName);
        setRoundDetail({
          currentRoundId: res.data.data.currentRoundId,
          currentRoundName: res.data.data.currentRoundName,
        });
      });
  };

  // Function get group api
  const GetGroup = () => {
    axios
      .get(getMain + wholeseller.wholesellerCode, {
        headers: { Authorization: AuthStr, "Access-Control-Allow-Origin": "*" },
      })
      .then((res) => {
        localStorage.setItem(
          "groupName",
          res.data.data.wholesellerDetail.groupName
        );

        setWholesellerDetail(res.data.data.wholesellerDetail);
        localStorage.setItem(
          "wholesellerDetailSalesman",
          JSON.stringify(res.data.data.wholesellerDetail)
        );
        localStorage.setItem("nameSalesman", res.data.data.employeeName);
        setRoundDetail({
          currentRoundId: roundName,
          currentRoundName: localStorage.getItem("roundName")?.toString() || "",
        });
       
        getLeaderboardStats();
      });
  };

  const getLeaderboardStats = () => {
    const dataLeaderboard = {
      groupName,
      roundId: localStorage.getItem("roundId"),
    };
    axios
      .post(getLeaderboard, dataLeaderboard, {
        headers: { Authorization: AuthStr, "Access-Control-Allow-Origin": "*" },
      })
      .then((res) => {
        setLeaderboards(res.data.data);
      })
      .catch((e) => {});
  };

  // Useeffect untuk call roundId dari localStorage
  useEffect(() => {
    if (localStorage.getItem("roundId") == null) {
      round();
    }
    GetGroup();
  }, [roundName, groupName]);
  
  const highlightColor = (name: any) => {
    return name === wholesellerCode ? "#FFE5B4" : undefined;
  };

  useEffect(() => {
    if (leaderboards.length > 0 && wholesellerCode) {
      const index = leaderboards.findIndex(
        (item) => item.wholesellerCode === wholesellerCode
      );
      if (index !== -1 && rowRefs.current[index]) {
        rowRefs.current[index]?.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }
  }, [leaderboards, wholesellerCode]);

  return (
    <div id="divOne" style={{ maxHeight: '400px', overflowY: 'scroll' }}>
    <table
      id="tblOne"
      className="table table-bordered table-striped mb-0"
    >
      <thead>
        <tr style={{ backgroundColor: "#ecbc44" }}>
          <th scope="col" id="SM-Rank">
            Rank
          </th>
          <th scope="col" id="SM-Rank">
            Nama Panel
          </th>
          <th scope="col" id="SM-Rank">
            Area
          </th>
          <th scope="col" id="SM-Rank" style={{ textAlign: "center" }}>
            Multiplier
          </th>
          <th scope="col" id="SM-Rank" style={{ textAlign: "center" }}>
            Base Point
          </th>
          <th scope="col" id="SM-Rank" style={{ textAlign: "center" }}>
            Total Point
          </th>
        </tr>
      </thead>
      <tbody className="table-leaderboardSM">
        {leaderboards && leaderboards.length > 0 ? (
          leaderboards.map((group, index) => (     
              <tr
                style={{
                  backgroundColor: highlightColor(group.wholesellerCode),
                }}
                ref={(el) => (rowRefs.current[index] = el)}
              >
                <td>{group.rank}</td>
                <td>{group.wholesellerName}</td>
                <td>{group.wholesellerArea}</td>
                <td style={{ textAlign: "right", paddingRight: "100px" }}>
                  {formatPointMultiplier(group.multiplier, true)}
                </td>
                <td style={{ textAlign: "right", paddingRight: "100px" }}>
                  {formatPoint(group.basepoint)}
                </td>
                <td style={{ textAlign: "right", paddingRight: "100px" }}>
                  {formatPoint(group.salePoint)}
                </td>
              </tr>
          ))
        ) : (
          <tr>
            <td colSpan={6} style={{ textAlign: "center" }}>
              Data tidak ditemukan
            </td>
          </tr>
        )}
      </tbody>
    </table>
  </div>
);
};

export default LeaderboardTable;
