import React, { FC } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useEffect } from "react";

import "./../../Style/header.css";
import "./../../Style/leaderboard.css";
import exit from "../../../Image/exit.png";
import newmainlogo from "../../../Image/newmainlogo.png";
import PopupDetail from "./../../PopUpComponents/PopupSalesman";
import TabsComponent from "../../TabsComponent/tabs";

type SomeComponentProps = RouteComponentProps;
interface HeaderSalesmanProps {
  groupName: string;
  // groupNameTopCriket: string;
  // groupNameTopDigital: string;
  wholesellerName: string;
  value: string; // Menambahkan prop value
  setValue: (value: string) => void; // Menambahkan prop setValue
}

const HeaderSalesman: FC<HeaderSalesmanProps> = ({
  wholesellerName,
  groupName,
  value,
  setValue,
}) => {
  const [user, setUser] = useState({
    employeeCode: "",
    employeeName: "",
    roleName: "",
    groupName: "",
  });

  let hist = useHistory();
  const logout = () => {
    localStorage.clear();
    hist.push("/");
  };

  const detailInformation = (currentWs: string, currentDetails: string) => {
    localStorage.setItem("wholesellerCode", currentWs);
    localStorage.setItem("detail", currentDetails);
    hist.push("/information");
  };

  // toggle data starts here//
  const [show, setShow] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  // toggle data ends here

  useEffect(() => {
    setUser({
      employeeCode: "",
      // employeeName: localStorage.getItem("nameSalesman") || "",
      employeeName: localStorage.getItem("employeeName") || "",
      roleName: "",
      groupName: localStorage.getItem("groupName") || "",
    });
  }, []);

  return (
    <>
      <header className="bg-main d-flex flex-wrap align-items-center justify-content-center justify-content-md-between py-3 p-2 text-light fixed-top">
        <div className="col-3 text-start">
          <button
            type="button"
            className="btn btn-link logout-button"
            onClick={logout}
          >
            <img
              src={exit}
              style={{
                height: "20px",
              }}
            />
            <br />
            <label className="outLabelSML" style={{ fontSize: "10px" }}>
              Keluar Aplikasi
            </label>
          </button>
        </div>

        <div className="nav col-6 col-md-auto mt-2 justify-content-center mb-md-0">
          <img
            src={newmainlogo}
            className="m-2"
            style={{
              height: "60px",
              zIndex: "1",
            }}
          />
          {/* <h1 style={{paddingRight: "20px"}}>{user.roleName}</h1> */}
        </div>
        {/* ? */}
        <div className="nav col-12 col-md-auto mt-2 justify-content-center mb-md-0">
          <h1 className="user-roleNameSM">
            {user.roleName} Group ({groupName})
          </h1>
        </div>
        {/* ? */}
        <div></div>

        <div className="col-3 text-end"></div>
      </header>
      <div className="bg-main" style={{ marginTop: "105px" }}>
        <div className="container">
          <div className="row">
            <div
              className="col-12 mb-2"
              style={{ whiteSpace: "nowrap", overflowX: "hidden" }}
            >
              <label className="text-light ms-2 username-SM">
                Halo, <b>{user.employeeName} </b>
              </label>
              {/* andakan yang diminta by wholeseller */}
              {/* <label className="text-light ms-2 username">Halo, <b>{props.wholesellerName} </b></label> */}

              <button
                onClick={() => setModalShow(true)}
                className="btn btn-warning float-end detail-information"
              >
                Informasi Detail
              </button>

              {modalShow && (
                <PopupDetail
                  show={modalShow}
                  onHide={() => setModalShow(false)}
                  value={value}
                />
              )}

              {/* click information detail start here old ones*/}
              {/* {show? <DetailBox/> : null} */}
              {/* click information detail end here old ones */}
            </div>
            <div className="col-12 text-end"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeaderSalesman;
