import React, { useEffect, useState } from 'react';
import { getProfile } from '../../../Helpers/Storage';
import paths from "../../../ApiServices.json";
import "./../../Style/header.css";
import "./../../Style/leaderboard.css";
import axios from "axios";
import { ToastContainer, toast, Flip } from "react-toastify";
import { useHistory } from 'react-router';

const NotFoundPage = () => {
  // const history = useHistory()
  // const [profile, setProfile] = useState(getProfile())
  // const [customerCode, setCustomerCode] = useState("");
  // const mappingLoginSSO = "https://localhost:7235/" + paths.employee.SSOlogin;
  // const username = profile.username
  // const headerSSO = btoa(username);
  // console.log(headerSSO)

  // const login = () => {
  //   axios
  //   .post(mappingLoginSSO, null, {
  //     headers: { "x-token": headerSSO, "Access-Control-Allow-Origin": "*" },
  //   })
  //   .then((res) => {
  //     console.log(res.data.data.mainData)
  //     if (
  //       res.data.data.firstLogin === false &&
  //       res.data.data.employeePhone == false
  //     ) {
  //       localStorage.setItem(
  //         "cumulativePoint",
  //         res.data.data.mainData.kumulatif
  //       );
  //       localStorage.setItem(
  //         "employeeCode",
  //         res.data.data.mainData.employeeCode
  //       );
  //       localStorage.setItem(
  //         "employeeName",
  //         res.data.data.mainData.employeeName
  //       );
  //       localStorage.setItem(
  //         "employeeRole",
  //         res.data.data.mainData.employeeRole
  //       );
  //       localStorage.setItem("roleName", res.data.data.mainData.roleName);
  //       localStorage.setItem(
  //         "wholesellerDetail",
  //         res.data.data.mainData.wholesellerDetail
  //       );
  //       localStorage.setItem("groupName", res.data.data.mainData.groupName);

  //       var roleType = res.data.data.mainData.employeeRole;
  //       switch (roleType) {
  //         case "A":
  //           localStorage.setItem("auth", res.data.data.token);
  //           setTimeout(() => {
  //             history.push("/admin");
  //           }, 3000);
  //           break;
  //         case "V":
  //           localStorage.setItem("auth", res.data.data.token);
  //           setTimeout(() => {
  //             history.push("/supervisor");
  //           }, 3000);
  //           break;
  //         case "S":
  //           localStorage.setItem("auth", res.data.data.token);
  //           setTimeout(() => {
  //             history.push("/salesman");
  //           }, 3000);
  //           break;
  //         case "M":
  //           localStorage.setItem("auth", res.data.data.token);
  //           setTimeout(() => {
  //             history.push("/areamanager");
  //           }, 3000);
  //           break;
  //         case "W":
  //           localStorage.setItem("auth", res.data.data.token);
  //           localStorage.setItem(
  //             "wholesellerCode",
  //             res.data.data.mainData.employeeCode
  //           );
  //           setTimeout(() => {
  //             history.push("/wholeseller");
  //           }, 3000);
  //           break;
  //       }
  //       toast.success(res.data.message, {
  //         position: "top-right",
  //         autoClose: 3000,
  //         hideProgressBar: true,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: false,
  //         progress: 0,
  //         toastId: "my_toast",
  //       });

  //       return;
  //     } else if (
  //       res.data.data.firstLogin === false &&
  //       res.data.data.employeePhone == true
  //     ) {
  //       // berarti ngambil dari sini?
  //       localStorage.setItem("customerCode", customerCode);
  //       // localStorage.setItem("auth", res.data.data.token);
  //       toast.success(res.data.message, {
  //         position: "top-right",
  //         autoClose: 3000,
  //         hideProgressBar: true,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: false,
  //         progress: 0,
  //         toastId: "my_toast",
  //       });
  //       setTimeout(() => {
  //         history.push("/updatephonenumber");
  //       }, 3000);
  //     } else {
  //       localStorage.setItem("customerCode", customerCode);
  //       // localStorage.setItem("auth", res.data.data.token);
  //       toast.success(res.data.message, {
  //         position: "top-right",
  //         autoClose: 3000,
  //         hideProgressBar: true,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: false,
  //         progress: 0,
  //         toastId: "my_toast",
  //       });
  //       setTimeout(() => {
  //         history.push("/firstlogin");
  //       }, 3000);
  //     }
  //   })
  //   .catch((error) => {
  //     if (
  //       error.response &&
  //       error.response.data &&
  //       error.response.data.message
  //     ) {
  //       toast.error(error.response.data.message, {
  //         position: "top-right",
  //         autoClose: 3000,
  //         hideProgressBar: true,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: false,
  //         progress: 0,
  //         toastId: "my_toast",
  //       });
  //     } else {
  //       // Menangani jika respons tidak memiliki struktur yang diharapkan
  //       toast.error("An error occurred", {
  //         position: "top-right",
  //         autoClose: 3000,
  //         hideProgressBar: true,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: false,
  //         progress: 0,
  //         toastId: "my_toast",
  //       });
  //     }
  //   });
  // }  

  // useEffect (() => {
  //   login()
  //   console.log("profile data", profile.username)
  // }, [])

  return (
    <div>
      {/* <h1> Dummy Page After Success Login SSO </h1> */}
      <h1>404 - Page Not Found</h1>
      <p>Maaf, halaman yang Anda cari tidak ditemukan.</p>
    </div>
  );
};

export default NotFoundPage;
