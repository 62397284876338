const setAuthorization = (data: any) => {
	try {
		localStorage.setItem("authorization", data.access_token);
		return true;
	} catch (error) {
		return false;
	}
};

const getAuthorization = () => {
	try {
		const encryptedData = localStorage.getItem("authorization");
		console.log(encryptedData)
        return encryptedData;
	} catch (error) {
		return null;
	}
};

const setSSOLogin = () => {
	try {
		const urlParams = new URLSearchParams(window.location.search);
		if (urlParams.has('code') || urlParams.has('Code')) {
			const codeValue = urlParams.get('code');
			return codeValue;
		}
		return null;
	} catch (error) {
		console.log("test error params : ", error);
		return null;
	}
}

const setProfile = (data: any) => {
    try {
        localStorage.setItem("profile", JSON.stringify(data));;
        return true
    } catch (error) {
        return false
    }
}

const getProfile = () => {
	try {
		const data = localStorage.getItem("profile");
		if (data === null) {
			return null;
		}
		return JSON.parse(data);
	} catch (error) {
		return null;
	}
}

const removeAuthFromStorage = () => {
    try {
        localStorage.removeItem("authorization");
		localStorage.removeItem("profile");
        return true
    } catch (error) {
        return false
    }
}



export {
    setAuthorization,
    getAuthorization,
    setSSOLogin,
    setProfile,
    getProfile,
    removeAuthFromStorage
}