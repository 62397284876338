import React from "react";

interface PaginationMessageProps {
  pageNumber: number;
  pageSize: number;
  totalData: number;
}

function PaginationMessage({
  pageNumber,
  pageSize,
  totalData,
}: PaginationMessageProps) {
  const lowerLimit = (pageNumber - 1) * pageSize + 1;
  const upperLimit = Math.min(pageNumber * pageSize, totalData);

  return (
    <div>
      Showing {lowerLimit} to {upperLimit} of {totalData} Entries
    </div>
  );
}

export default PaginationMessage;
