import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { formatPoint, formatPointMultiplier } from "./Popup";

const PopupDetail = (props: any) => {
  const { value, ...modalProps } = props;

  const dataLeaderboard = {
    employeeName: localStorage.getItem("nameSalesman") || localStorage.getItem("employeeName"),
    wholesellerDetail: JSON.parse(
      localStorage.getItem("wholesellerDetailSalesman") || "{}"
    ),
  };

  const dataCricket = {
    employeeName: localStorage.getItem("nameSalesman") || localStorage.getItem("employeeName"),
    topCricketPerformanceVM: JSON.parse(
      localStorage.getItem("topCricketPerformanceVM") || "{}"
    ),
  };

  const dataDigital = {
    employeeName: localStorage.getItem("nameSalesman") || localStorage.getItem("employeeName"),
    topDigitalPerformanceVM: JSON.parse(
      localStorage.getItem("topDigitalPerformanceVM") || "{}"
    ),
  };

  const dataRetailer = {
    retailerDetail: JSON.parse(localStorage.getItem("retailerDetail") || "{}"),
  };

  const safeFormatPoint = (point: any) => {
    return point ? formatPoint(point) : 0;
  };

  const safeReplaceDate = (date: any) => {
    return date ? date.replace("T00:00:00", "") : "N/A";
  };

  const renderLeaderboardDetail = () => {
    if (
      dataLeaderboard.wholesellerDetail != null ||
      dataLeaderboard.wholesellerDetail != undefined
    ) {
      return (
        <div>
          <tr>
            <td>Wholesaler Name: {dataLeaderboard.employeeName}</td>
          </tr>
          <tr>
            <td>
              Point SMP:{" "}
              {safeFormatPoint(dataLeaderboard.wholesellerDetail.pointB)}
            </td>
          </tr>
          <tr>
            <td>
              Point Multicategory & Gamification:{" "}
              {safeFormatPoint(dataLeaderboard.wholesellerDetail.pointA)}
            </td>
          </tr>
          <tr>
            <td>
              Multiplier:{" "}
              {dataLeaderboard.wholesellerDetail.multiplier !== undefined
                ? formatPointMultiplier(
                    dataLeaderboard.wholesellerDetail.multiplier,
                    true
                  )
                : "N/A"}
            </td>
          </tr>
          <tr>
            <td>
              Total Point:{" "}
              {safeFormatPoint(dataLeaderboard.wholesellerDetail.salePoint)}
            </td>
          </tr>
          <tr>
            <td>
              Point Tahunan:{" "}
              {safeFormatPoint(dataLeaderboard.wholesellerDetail.kumulatif)}
            </td>
          </tr>
          <tr>
            <td>
              Target Stock Mingguan:{" "}
              {safeFormatPoint(dataLeaderboard.wholesellerDetail.baselineStock)}
            </td>
          </tr>
          <tr>
            <td>
              Target Private Label Es.Er.Ce Round Ini:{" "}
              {safeFormatPoint(dataLeaderboard.wholesellerDetail.targetPrivate)}
            </td>
          </tr>
          <tr>
            <td>
              Update Data Hingga Tanggal:{" "}
              {safeReplaceDate(dataLeaderboard.wholesellerDetail.updateData)}
            </td>
          </tr>
        </div>
      );
    } else {
      return (
        <div>
          <tr>
            <td>Wholesaler Name: </td>
          </tr>
          <tr>
            <td>Point SMP:</td>
          </tr>
          <tr>
            <td>Point Multicategory & Gamification:</td>
          </tr>
          <tr>
            <td>Multiplier:</td>
          </tr>
          <tr>
            <td>Total Point:</td>
          </tr>
          <tr>
            <td>Point Tahunan:</td>
          </tr>
          <tr>
            <td>Target Stock Mingguan:</td>
          </tr>
          <tr>
            <td>Target Private Label Es.Er.Ce Round Ini:</td>
          </tr>
          <tr>
            <td>Update Data Hingga Tanggal:</td>
          </tr>
        </div>
      );
    }
  };

  const renderCricketDetail = () => {
    if (dataCricket.topCricketPerformanceVM != null) {
      return (
        <div>
          <tr>
            <td>Wholesaler Name: {dataCricket.employeeName}</td>
          </tr>
          <tr>
            <td>
              Total Point:{" "}
              {safeFormatPoint(dataCricket.topCricketPerformanceVM.salePoint)}
            </td>
          </tr>
          <tr>
            <td>Rank: {dataCricket.topCricketPerformanceVM.currentRank}</td>
          </tr>
          <tr>
            <td>
              Wholesaler Area:{" "}
              {dataCricket.topCricketPerformanceVM.wholesellerArea}{" "}
            </td>
          </tr>
          <tr>
            <td>
              Update Data Hingga Tanggal:{" "}
              {safeReplaceDate(dataCricket.topCricketPerformanceVM.updateData)}
            </td>
          </tr>
        </div>
      );
    } else {
      return (
        <div>
          <tr>
            <td>Wholesaler Name: {dataCricket.employeeName}</td>
          </tr>
          <tr>
            <td>Total Point: 0</td>
          </tr>
          <tr>
            <td>Rank: 0</td>
          </tr>
          <tr>
            <td>Wholesaler Area: 0</td>
          </tr>
          <tr>
            <td>Update Data Hingga Tanggal: N/A</td>
          </tr>
        </div>
      );
    }
  };

  const renderDigitalDetail = () => {
    if (dataDigital.topDigitalPerformanceVM != null) {
      return (
        <div>
          <tr>
            <td>Wholesaler Name: {dataDigital.employeeName}</td>
          </tr>
          <tr>
            <td>
              Total Point:{" "}
              {safeFormatPoint(dataDigital.topDigitalPerformanceVM.salePoint)}
            </td>
          </tr>
          <tr>
            <td>Rank: {dataDigital.topDigitalPerformanceVM.currentRank}</td>
          </tr>
          <tr>
            <td>
              Wholesaler Area:{" "}
              {dataDigital.topDigitalPerformanceVM.wholesellerArea}
            </td>
          </tr>
          <tr>
            <td>
              Update Data Hingga Tanggal:{" "}
              {safeReplaceDate(dataDigital.topDigitalPerformanceVM.updateData)}
            </td>
          </tr>
        </div>
      );
    } else {
      return (
        <div>
          <tr>
            <td>Wholesaler Name: {dataDigital.employeeName}</td>
          </tr>
          <tr>
            <td>Total Point: 0</td>
          </tr>
          <tr>
            <td>Rank: 0</td>
          </tr>
          <tr>
            <td>Wholesaler Area: 0</td>
          </tr>
          <tr>
            <td>Update Data Hingga Tanggal: N/A</td>
          </tr>
        </div>
      );
    }
  };

  const renderRetailerDetail = () => {
    if (dataRetailer.retailerDetail != null) {
      return (
        <div>
          <tr>
            <td>Customer Code : {dataRetailer.retailerDetail.customerCode}</td>
          </tr>
          <tr>
            <td>Retailer Name : {dataRetailer.retailerDetail.retailerName}</td>
          </tr>
          <tr>
            <td>Group Name : {dataRetailer.retailerDetail.groupName}</td>
          </tr>
          <tr>
            <td>SMP : {safeFormatPoint(dataRetailer.retailerDetail.smp)}</td>
          </tr>
          <tr>
            <td>PVP : {safeFormatPoint(dataRetailer.retailerDetail.pvp)}</td>
          </tr>
          <tr>
            <td>CPD : {safeFormatPoint(dataRetailer.retailerDetail.cpd)}</td>
          </tr>
          <tr>
            <td>
              Cricket : {safeFormatPoint(dataRetailer.retailerDetail.cricket)}
            </td>
          </tr>
          <tr>
            <td>DTE : {safeFormatPoint(dataRetailer.retailerDetail.dte)}</td>
          </tr>
          <tr>
            <td>POSM : {safeFormatPoint(dataRetailer.retailerDetail.posm)}</td>
          </tr>
          <tr>
            <td>
              Jumlah Minggu Aktif Digital :{" "}
              {safeFormatPoint(dataRetailer.retailerDetail.b2B_CitaTransaction)}
            </td>
          </tr>
          <tr>
            <td>
              Multiplier :{" "}
              {dataRetailer.retailerDetail.multiplier !== undefined
                ? formatPointMultiplier(
                    dataRetailer.retailerDetail.multiplier,
                    true
                  )
                : "N/A"}
            </td>
          </tr>
          <tr>
            <td>
              Base Point :{" "}
              {safeFormatPoint(dataRetailer.retailerDetail.basePoint)}
            </td>
          </tr>
          <tr>
            <td>
              Total Point :{" "}
              {safeFormatPoint(dataRetailer.retailerDetail.totalPoint)}
            </td>
          </tr>
          <tr>
            <td>
              Update Data Hingga Tanggal :{" "}
              {safeReplaceDate(dataRetailer.retailerDetail.updateData)}
            </td>
          </tr>
        </div>
      );
    } else {
      return (
        <div>
          <tr>
            <td>Customer Code : </td>
          </tr>
          <tr>
            <td>Retailer Name : </td>
          </tr>
          <tr>
            <td>Group Name : </td>
          </tr>
          <tr>
            <td>SMP : </td>
          </tr>
          <tr>
            <td>PVP : </td>
          </tr>
          <tr>
            <td>CPD : </td>
          </tr>
          <tr>
            <td>Cricket :</td>
          </tr>
          <tr>
            <td>DTE : </td>
          </tr>
          <tr>
            <td>POSM : </td>
          </tr>
          <tr>
            <td>Jumlah Minggu Aktif Digital :</td>
          </tr>
          <tr>
            <td>Multiplier :</td>
          </tr>
          <tr>
            <td>Base Point :</td>
          </tr>
          <tr>
            <td>Total Point :</td>
          </tr>
          <tr>
            <td>Update Data Hingga Tanggal :</td>
          </tr>
        </div>
      );
    }
  };

  return (
    <Modal
      {...modalProps}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Informasi Detail
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {value === "1" && renderLeaderboardDetail()}
        {value === "2" && renderCricketDetail()}
        {value === "3" && renderDigitalDetail()}
        {value === "4" && renderRetailerDetail()}
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="btn-warning text-center shadow-none"
          onClick={props.onHide}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PopupDetail;
