import React, { FC, Fragment, useEffect, useState } from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import HeaderSalesman from "../Header/HeaderSalesman";
import "./../../Style/leaderboard.css";
import TabsComponent from "../../TabsComponent/tabs";
import WholesalerNational from "./WholesalerNational";
import Wholeseller from "../WholeSeller/Wholeseller";

type SomeComponentProps = RouteComponentProps;
const WholesalerNationalLeaderboard: FC<SomeComponentProps> = ({ history }) => {
  const stat: any = history.location.state;
   // Mengelola state value untuk tab yang aktif
   const [value, setValue] = useState("1");
   const [groupName, setGroupName] = useState("");
   const [groupNameCricket, setGroupNameCricket] = useState("");
   const [groupNameDigital, setGroupNameDigital] = useState("");
   const groupNameLeaderboard = localStorage.getItem('groupName') || "";

  useEffect(() => {
    setGroupName(stat.groupName);
    localStorage.setItem("groupName", stat.groupName);
  }, [stat.groupName]);

  useEffect(() => {
    setGroupNameCricket(stat.groupNameTopCriket);
    localStorage.setItem("groupNameTopCriket", stat.groupNameTopCriket);
  }, [stat.groupNameTopCriket]);


  useEffect(() => {
    setGroupNameDigital(stat.groupNameTopDigital);
    localStorage.setItem("groupNameTopDigital", stat.groupNameTopDigital);
  }, [stat.groupNameTopDigital]);


   // Function untuk menghapus item dari localStorage
   const handleBackClick = () => {
    localStorage.removeItem("nameSalesman");
    localStorage.removeItem("wholesellerDetailSalesman");
    localStorage.removeItem("topCricketPerformanceVM");
    localStorage.removeItem("topDigitalPerformanceVM");
    localStorage.removeItem("groupName");    
    localStorage.removeItem("groupNameTopCriket");
    localStorage.removeItem("groupNameTopDigital");
    
     // Navigasi kembali ke halaman sebelumnya
    history.push("/wholesalernational");
   };


   const getGroupName = (wholesellerT: any) => {
    if (wholesellerT.groupName) {
      return wholesellerT.groupName;
    } else if (wholesellerT.groupNameTopCriket) {
      return wholesellerT.groupNameTopCriket;
    } else if (wholesellerT.groupNameTopDigital) {
      return wholesellerT.groupNameTopDigital;
    } else {
      return "None";
    }
  };

  return (
    <Fragment>
      <HeaderSalesman
        groupName={groupName}
        wholesellerName={stat.wholesellerName}
        value={value}
        setValue={setValue}
      />
      <div
        className="col ms-1"
        style={{
          paddingLeft: "0px",
          paddingBottom: "4px",
          fontSize: "larger",
        }}
      >
        <button onClick={handleBackClick} className="btn btn-link" style={{ textDecoration: 'none', color: 'black' }}>
          <i className="fa fa-arrow-left m-3" style={{ color: 'black' }}/>
        </button>
        <label className="p-3" style={{ marginLeft: "-20px" }}>
          <b>Admin</b>
        </label>
      </div>
      <TabsComponent 
        wholesellerCode={stat.wholesellerCode} 
        roundId={stat.roundId} 
        value={value}
        setValue={setValue}
        updateGroupName={setGroupName}
      />
    </Fragment>
  );
};

export default WholesalerNationalLeaderboard;
