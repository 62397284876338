import React, { FC, Fragment, useEffect, useRef, useState } from "react";
import axios from "axios";
import { formatPoint } from "../PopUpComponents/Popup";
import "../Style/leaderboard.css";
import paths from "../../ApiServices.json";

interface TopDigitalTableProps {
  wholesellerCode: string;
  groupName: string;
  roundId?: number;
  scrollToEntry: boolean
}

const TopCricketTable: FC<TopDigitalTableProps> = ({
  wholesellerCode,
  groupName,
  roundId,
  scrollToEntry
}) => {
  // get api
  const getTopDigital = paths.employee.getTopDigital;
  const storedRoundId = localStorage.getItem("roundId");
  const effectiveRoundId =
    roundId ?? (storedRoundId ? parseInt(storedRoundId) : undefined);
  const [topDigitals, setTopDigitals] = useState<any[]>([]);
  const rowRefs = useRef<(HTMLTableRowElement | null)[]>([]);

  const USER_TOKEN = localStorage.getItem("auth");
  const AuthStr = "Bearer " + USER_TOKEN;

  // constanta untuk dapetin wholeseller code yang dipake untuk hover highlight
  const codeWholesellerHighlight: string | null =
    localStorage.getItem("wholesellerCode");

  // state wholesellerdetail
  const [wholesellerDetail, setWholesellerDetail] = useState<any[]>([]);

  // Function get group api
  const GetGroup = () => {
    axios
      .get(paths.employee.getDigitalPerformance + wholesellerCode, {
        headers: { Authorization: AuthStr, "Access-Control-Allow-Origin": "*" },
      })
      .then((res) => {
        localStorage.setItem(
          "groupNameTopDigital",
          res.data.data.topDigitalPerformanceVM.groupName
        );
        setWholesellerDetail(res.data.data.topDigitalPerformanceVM);
        localStorage.setItem(
          "topDigitalPerformanceVM",
          JSON.stringify(res.data.data.topDigitalPerformanceVM)
        );
        getDigitalStats();
      });
  };

  // Function to get cricket stats based on the provided parameters
  const getDigitalStats = () => {
    const dataDigital = {
      groupName,
      roundId: effectiveRoundId,
    };

    axios
      .post(getTopDigital, dataDigital, {
        headers: { Authorization: AuthStr, "Access-Control-Allow-Origin": "*" },
      })
      .then((res) => {
        setTopDigitals(res.data.data);
      })
      .catch((e) => {
        // console.error(e);
      });
  };

  useEffect(() => {
    if (effectiveRoundId !== undefined) {
      getDigitalStats();
    }
    GetGroup();
  }, [groupName, effectiveRoundId]);

  // Highlight color
  function highlightColor(name: any) {
    if (name == localStorage.getItem("wholesellerCode")) {
      return "#FFE5B4";
    }
  }

  useEffect(() => {
    if (scrollToEntry && topDigitals.length > 0 && codeWholesellerHighlight) {
      const index = topDigitals.findIndex(
        (item) => item.wholesellerCode === codeWholesellerHighlight
      );
      if (index !== -1 && rowRefs.current[index]) {
        rowRefs.current[index]?.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }
  }, [scrollToEntry, topDigitals, codeWholesellerHighlight]);

  return (
    <div id="divOne" style={{ maxHeight: "400px", overflowY: "scroll" }}>
      <table
        id="tblOne"
        className="table table-bordered table-striped mb-0"
        style={{ overflowY: "scroll" }}
      >
        <thead>
          <tr style={{ backgroundColor: "#ecbc44" }}>
            <th scope="col" id="SM-Rank">
              Rank
            </th>
            <th scope="col" id="SM-Rank">
              Nama Panel
            </th>
            <th scope="col" id="SM-Rank">
              Area
            </th>
            <th scope="col" id="SM-Rank" style={{ textAlign: "center" }}>
              Total Point
            </th>
          </tr>
        </thead>
        <tbody className="table-topDigitalSM">
          {topDigitals && topDigitals.length > 0 ? (
            topDigitals.map((group, index) => (
              <tr
                key={index}
                style={{
                  backgroundColor: highlightColor(group.wholesellerCode),
                }}
                ref={(el) => (rowRefs.current[index] = el)}
              >
                <td>{group.rank}</td>
                <td>{group.wholesellerName}</td>
                <td>{group.wholesellerArea}</td>
                <td style={{ textAlign: "right", paddingRight: "100px" }}>
                  {formatPoint(group.salePoint)}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={4} style={{ textAlign: "center" }}>
                Data tidak ditemukan
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default TopCricketTable;
