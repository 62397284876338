import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import Login from "./Components/Pages/Login/Login";
import ForgotPassword from "./Components/Pages/Login/ForgotPassword";
import FirstLogin from "./Components/Pages/Login/FirstLogin";
import Salesman from "./Components/Pages/Salesman/Salesman";
import ResetPassword from "./Components/Pages/Login/ResetPassword";
import RestrictedRoute from "./Auth/RestrictedRoute";
import PrivateRoute from "./Auth/PrivateRoute";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import Admin from "./Components/Pages/Admin/Admin";
import Round from "./Components/Pages/Admin/Round";
import MaintainProfile from "./Components/Pages/Admin/Maintainprofile";
import UploadData from "./Components/Pages/Admin/UploadData";
import RoundSetting from "./Components/Pages/Admin/RoundSetting";
import WholesellerPage from "./Components/Pages/WholeSeller/WholesellerPage";
import SalesmanLeaderboard from "./Components/Pages/Salesman/SalesmanLeaderboard";
import HeaderNew from "./Components/Pages/Header/HeaderNew";
import PhoneNumber from "./Components/Pages/Login/PhoneNumber";
import "../src/App.css";
import Supervisor from "./Components/Pages/Supervisor/Supervisor";
import SupervisorLeaderboard from "./Components/Pages/Supervisor/SupervisorLeaderboard";
import AreaManager from "./Components/Pages/Area Manager/AreaManager";
import AreaManagerLeaderboard from "./Components/Pages/Area Manager/AreaManagerLeaderboard";
import WholesalerNational from "./Components/Pages/Admin/WholesalerNational";
import WholesalerNationalLeaderboard from "./Components/Pages/Admin/WholesalerNationalLeaderboard";
import NotFoundPage from "./Components/Pages/404/404Page";
import LoginInternalSSO from "./Components/Pages/Login/LoginInternalSSO";
import RetailerPage from "./Components/Pages/Retailer/RetailerPage";
import RetailerNationalLeaderboard from "./Components/Pages/Admin/RetailerNationalLeaderboard";
import AreaRetailerLeaderboard from "./Components/Pages/Area Manager/AreaRetailerLeaderboard";
import SupervisorRetailerLeaderboard from "./Components/Pages/Supervisor/SupervisorRetailerLeaderoard";
import SalesmanRetailerLeaderboard from "./Components/Pages/Salesman/SalesmanRetailerLeaderboard";

function App() {
  return (
      <BrowserRouter>
        <Switch>
          <Route exact path="/">
            <Redirect to="/login" />
          </Route>
          <RestrictedRoute exact path="/login" component={Login} />
          <RestrictedRoute
            exact
            path="/logininternal"
            component={LoginInternalSSO}
          />
          <Route exact path="/forgotpassword" component={ForgotPassword} />
          <Route exact path="/resetpassword" component={ResetPassword} />
          <Route exact path="/firstlogin" component={FirstLogin} />
          <PrivateRoute exact path="/salesman" component={Salesman} />
          <PrivateRoute
            exact
            path="/leaderboards"
            component={SalesmanLeaderboard}
          />
          <PrivateRoute exact path="/areamanager" component={AreaManager} />
          <PrivateRoute
            exact
            path="/areamanagerleaderboards"
            component={AreaManagerLeaderboard}
          />
          <PrivateRoute exact path="/supervisor" component={Supervisor} />
          <PrivateRoute
            exact
            path="/supervisorleaderboards"
            component={SupervisorLeaderboard}
          />
          <PrivateRoute exact path="/admin" component={Admin} />
          <PrivateRoute exact path="/round" component={Round} />
          <PrivateRoute
            exact
            path="/maintainprofile"
            component={MaintainProfile}
          />
          <PrivateRoute exact path="/uploaddata" component={UploadData} />
          <PrivateRoute
            exact
            path="/wholesalernational"
            component={WholesalerNational}
          />
          <PrivateRoute
            exact
            path="/wholesalernationalleaderboard"
            component={WholesalerNationalLeaderboard}
          />
          <PrivateRoute
            exact
            path="/retailernationalleaderboard"
            component={RetailerNationalLeaderboard}
          />
          <PrivateRoute
            exact
            path="/arearetailerleaderboard"
            component={AreaRetailerLeaderboard}
          />
          <PrivateRoute
            exact
            path="/supervisorretailerleaderboard"
            component={SupervisorRetailerLeaderboard}
          />
          <PrivateRoute
            exact
            path="/salesmanretailerleaderboard"
            component={SalesmanRetailerLeaderboard}
          />

          <PrivateRoute exact path="/wholeseller" component={WholesellerPage} />
          <PrivateRoute exact path="/retailer" component={RetailerPage} />
          <PrivateRoute exact path="/roundsetting" component={RoundSetting} />
          <Route exact path="/404Page" component={NotFoundPage} />
          <Route exact path="/updatephonenumber" component={PhoneNumber} />
        </Switch>
      </BrowserRouter>
  );
}

export default App;
